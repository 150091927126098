exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-asing-index-jsx": () => import("./../../../src/pages/asing/index.jsx" /* webpackChunkName: "component---src-pages-asing-index-jsx" */),
  "component---src-pages-bootsselect-index-jsx": () => import("./../../../src/pages/bootsselect/index.jsx" /* webpackChunkName: "component---src-pages-bootsselect-index-jsx" */),
  "component---src-pages-bootssize-index-jsx": () => import("./../../../src/pages/bootssize/index.jsx" /* webpackChunkName: "component---src-pages-bootssize-index-jsx" */),
  "component---src-pages-cantadjustment-index-jsx": () => import("./../../../src/pages/cantadjustment/index.jsx" /* webpackChunkName: "component---src-pages-cantadjustment-index-jsx" */),
  "component---src-pages-skimatch-index-jsx": () => import("./../../../src/pages/skimatch/index.jsx" /* webpackChunkName: "component---src-pages-skimatch-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */)
}

